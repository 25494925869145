<template>
    <div class="merchantInfo">
        <app-header :title="title"></app-header>
        <div class="merchantInfo-header">
            <div class="cont">
                <span>我的钱包</span>
                <div class="cont_box">
                    <div style="border-right: 1px solid #cccccc;width:240px;padding-left:20px;">
                        <div class="cont_title">{{allAmount}}</div>
                        <div class="cont_text1">钱包总额（¥）</div>
                        <div class="cont_text2">结算后钱包总收益，包含冻结金额+提现审核中金额</div>
                    </div>
                    <div style="border-right: 1px solid #cccccc;width:240px;padding-left:20px;">
                        <div class="cont_title">{{freezenAmount}}</div>
                        <div class="cont_text1">冻结金额（¥）</div>
                        <div class="cont_text2">当日结算后总金额，待解冻后才可提现</div>
                    </div>
                    <div style="width:240px;padding-left:20px;">
                        <div class="cont_title">{{canWithdrawnAmount}}</div>
                        <div class="cont_text1">可提现（¥）</div>
                        <div class="cont_text2">可提现金额</div>
                    </div>
                    <!-- 绑定银行账户信息才显示 -->
                    <span v-show="isShow&&state==1" class="cont_withdrawn" @click="withdrawnFun()">提现</span>
                </div>
            </div>
            <div class="cont">
                <span>我的提现</span>
                <div class="cont_box">
                    <div style="border-right: 1px solid #cccccc;width:240px;padding-left:20px;">
                        <div class="cont_title">{{lastAmount}}</div>
                        <div class="cont_text1">最近一次提现（¥）</div>
                    </div>
                    <div style="border-right: 1px solid #cccccc;width:240px;padding-left:20px;">
                        <div class="cont_title">{{monthAmount}}</div>
                        <div class="cont_text1">本月提现（¥）</div>
                    </div>
                    <div style="width:240px;padding-left:20px;">
                        <div class="cont_title">{{totalAmount}}</div>
                        <div class="cont_text1">累计提现（¥）</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="merchantInfo_infoBox">
            <div class="merchantInfo_info">
                <div class="base-info">
                    <span class="info-title">基本信息</span><span
                        style="color:#30BAC1;padding-left:10px;">({{stateStr}})</span>
                    <div class="row">
                        <div class="row-title">注册主体类型</div>
                        <el-input class="row-value" v-model="typeStr" disabled></el-input>
                    </div>
                    <div class="row">
                        <div class="row-title">{{type == 1 ? '姓名' : '企业名称'}}</div>
                        <el-input class="row-value" v-model="name" disabled></el-input>
                    </div>
                    <div v-if="type == 2">
                        <div class="row">
                            <div class="row-title">统一信用代码</div>
                            <el-input class="row-value" v-model="creditCode" disabled></el-input>
                        </div>
                        <div class="row">
                            <div class="row-title">企业法人姓名</div>
                            <el-input class="row-value" v-model="legalName" disabled></el-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row-title">{{type == 1 ? '性别' : '法人性别'}}</div>
                        <el-input class="row-value" v-model="sexStr" disabled></el-input>
                    </div>
                    <div class="row">
                        <div class="row-title">{{type == 1 ? '证件类型' : '法人证件类型'}}</div>
                        <el-input class="row-value" v-model="cardTypeStr" disabled></el-input>
                    </div>
                    <div class="row">
                        <div class="row-title">{{type == 1 ? '证件号码' : '法人证件号码'}}</div>
                        <el-input class="row-value" v-model="cardNo" disabled></el-input>
                    </div>
                    <div class="row">
                        <div class="row-title">证件有效期</div>
                        <el-input class="row-value" v-model="validate" disabled></el-input>
                    </div>
                    <div class="row address">
                        <div class="row-title">联系地址</div>
                        <el-input type="textarea" class="row-value" :autosize="{ maxRows: 6}" v-model="address"
                            disabled></el-input>
                    </div>
                    <div class="row">
                        <div class="row-title">手机号码（登录账号）</div>
                        <el-input class="row-value" v-model="mobile" disabled></el-input>
                    </div>
                    <div class="row">
                        <div class="row-title">登录密码</div>
                        <el-input class="row-value" v-model="password" disabled></el-input>
                    </div>
                    <div v-if="type == 2">
                        <el-form ref="myForm" :model="myForm" :rules="myFormRules">
                            <el-form-item label-width="208px" label="法人身份证国徽面" style="width:100%;"
                                prop="identityReverseUrl">
                                <el-upload v-if="!notEdit" class="avatar-uploader" accept="image/*"
                                    list-type="picture-card" name="file" :action="picUrl"
                                    :file-list="myForm.identityReverseUrl" :before-remove="onBeforeRemoveByUploadImg()"
                                    :on-success="onSuccessByUploadCardUrl1()" :on-remove="onHandleRemoveCardUrl1()"
                                    :class="{'one-upload-disabled': myForm.identityReverseUrl.length>0}">
                                    <i v-if="myForm.identityReverseUrl.length<1" class="el-icon-plus"></i>
                                </el-upload>
                                <div class="img-list" v-if="notEdit">
                                    <img v-for="it in myForm.identityReverseUrl" :key="it.url" :src="it.url"
                                        @click="showBig(it.url)" />
                                </div>
                            </el-form-item>
                            <el-form-item label-width="208px" label="法人身份证人像面" prop="identityFrontUrl">
                                <el-upload v-if="!notEdit" class="avatar-uploader" accept="image/*"
                                    list-type="picture-card" :action="picUrl" :file-list="myForm.identityFrontUrl"
                                    :before-remove="onBeforeRemoveByUploadImg()"
                                    :on-success="onSuccessByUploadCardUrl2()" :on-remove="onHandleRemoveCardUrl2()"
                                    :class="{'one-upload-disabled': myForm.identityFrontUrl.length>0}">
                                    <i v-if="myForm.identityFrontUrl.length<1" class="el-icon-plus"></i>
                                </el-upload>
                                <div class="img-list" v-if="notEdit">
                                    <img v-for="it in myForm.identityFrontUrl" :key="it.url" :src="it.url"
                                        @click="showBig(it.url)" />
                                </div>
                            </el-form-item>
                            <el-form-item label-width="208px" label="营业执照" style="width:100%;" prop="certificateUrl">
                                <el-upload v-if="!notEdit" class="avatar-uploader" accept="image/*"
                                    list-type="picture-card" :action="picUrl" :file-list="myForm.certificateUrl"
                                    :before-remove="onBeforeRemoveByUploadImg()"
                                    :on-success="onSuccessByUploadImgOnly()" :on-remove="onHandleRemove()"
                                    :class="{'one-upload-disabled': myForm.certificateUrl.length>0}">
                                    <i v-if="myForm.certificateUrl.length<1" class="el-icon-plus"></i>
                                </el-upload>
                                <div class="img-list" v-if="notEdit">
                                    <img v-for="it in myForm.certificateUrl" :key="it.url" :src="it.url"
                                        @click="showBig(it.url)" />
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>


                </div>
                <div class="base-info">
                    <div class="info-title">其他信息</div>
                    <!-- 商家才显示分润场景 -->
                    <div class="row" v-if="state==1 && userInfo.roleType == 10">
                        <div class="row-title">分润场景</div>
                        <el-select style="width: 300px;display: inline-block;" v-model="merchantType" disabled>
                            <el-option v-for="item in modelList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="row-tip">{{modelTip}}</div>
                    </div>
                    <div class="row">
                        <div class="row-title">{{auditName}}</div>
                        <el-input class="row-value" v-model="stateStr" disabled></el-input>
                    </div>
                </div>
                <div v-show="!notEdit&&type==2" class="editInfoBtn">
                    <el-button type="primary" @click="editInfoFun()">提交</el-button>
                </div>
            </div>
            <!-- 未绑定 -->
            <div class="account_info" v-if="!bindState">
                <div class="base-info">
                    <!-- 个人 -->
                    <div v-show="type == 1">
                        <div class="info-title">银行账户信息</div>
                        <div class="bindAccount-button" @click="bindAccont(1)">绑定个人账户</div>
                        <div class="bindAccount-tip">重要提醒：</div>
                        <div class="bindAccount-tip">1. 请继续绑定银行账户信息；</div>
                        <div class="bindAccount-tip">2. 绑定银行账户信息，用于提现至个人银行账户。</div>
                    </div>
                    <!-- 企业 -->
                    <div v-show="type == 2">
                        <div class="info-title">银行账户信息（对公账户）</div>
                        <div class="bindAccount-button" @click="bindAccont(2)">绑定企业对公银行账户</div>
                        <div class="bindAccount-tip">重要提醒：</div>
                        <div class="bindAccount-tip">1. 请继续绑定企业对公银行账户信息；</div>
                        <div class="bindAccount-tip">2. 绑定企业对公银行账户信息，用于提现至企业对公银行账户,</div>
                        <div class="bindAccount-tip" style="color: red;">3. 企业用户必须绑定对公银行账户，否则无法提现。</div>
                    </div>
                </div>
            </div>
            <div class="account_info" v-if="bindState">
                <!-- 已绑定 -->
                <div class="info-title"><span style="padding-right: 30px">{{type == 1 ? '银行账户信息' : '银行账户信息（对公账户）' }}</span>
                    <!-- 个人 才可解绑 -->
                    <el-button v-show="type == 1" type="primary" plain @click="Unbinding()">更换银行卡</el-button>
                </div>
                <div class="row">
                    <div class="row-title">开户行名称</div>
                    <el-input class="row-value" v-model="openBank" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">支行行名</div>
                    <el-input class="row-value" v-model="branchBank" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">支行行号</div>
                    <el-input class="row-value" v-model="branchBankNo" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">账户名称</div>
                    <el-input class="row-value" v-model="accountName" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">账号</div>
                    <el-input class="row-value" v-model="bankNo" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">{{type == 1 ? '银行预留手机号码' : '对公账户法人手机号码'}}</div>
                    <el-input class="row-value" v-model="bankPhoneStr" disabled></el-input>
                </div>
                <!--  企业需要绑定手机号-->
                <div v-if="type == 2">
                    <template v-if="!bindMobileState">
                        <div style="margin-left:70px;">
                            <div class="info-title">绑定企业法人手机号码</div>
                            <div class="bindAccount-button" @click="bindPhone">绑定手机号码</div>
                            <div class="bindAccount-tip">重要提醒：</div>
                            <div class="bindAccount-tip">1. 请先绑定手机号，否则影响企业审核；</div>
                            <div class="bindAccount-tip" style="color: red;">2. 此手机号码将作为商户系统用户标识，商户系统中唯一编号；</div>
                        </div>
                    </template>
                    <template v-if="bindMobileState">
                        <div class="info-title">企业法人手机号码</div>
                        <el-input style="width: 300px;display: inline-block;margin-left:220px;"
                            v-model.trim="memberMobile" disabled>
                        </el-input>
                    </template>
                </div>

            </div>
        </div>
        <div class="bindPhone">
            <el-dialog :visible.sync="dialogVisiblePhone" :close-on-click-modal="false" @close="cancelBtn">
                <!-- 绑定企业法人手机号码 -->
                <div class="bindPhoneFormTitle">绑定企业法人手机号码（商户系统用户唯一标识）</div>
                <el-form ref="bindPhoneForm" :model="bindPhoneForm" :rules="rules">
                    <el-form-item label-width='80px' prop="phone">
                        <el-input maxlength="50" style="width: 320px;display: inline-block;" placeholder="请输入手机号"
                            v-model.trim="bindPhoneForm.phone" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label-width='80px' prop="smsCode">
                        <el-input maxlength="50" style="width: 320px;display: inline-block;" placeholder="请输入验证码"
                            v-model.trim="bindPhoneForm.smsCode" clearable>
                        </el-input>
                        <span v-show="!showTite" class="getCode" style="color: #666666;">{{ this.count }}s</span>
                        <span v-show="showTite" class="getCode" @click="getCode(bindPhoneForm.phone)">获取验证码</span>
                    </el-form-item>
                    <div class="submitBox">
                        <el-button class="submitBox-button" type="primary" @click="sbumit()">提交</el-button>
                    </div>
                </el-form>
            </el-dialog>
        </div>
        <div class="withdrawn">
            <el-dialog :title="step == 1 ? '提现' : '安全校验'" :visible.sync="dialogVisibleWithdrawn"
                :close-on-click-modal="false">
                <div class="withdrawn_content" v-if="step==1">
                    <div class="withdrawn_row">
                        <div class="withdrawn_row_title">剩余可提现金额：</div>
                        <span class="withdrawn_amount">{{canWithdrawnAmount}}</span>
                    </div>
                    <div class="withdrawn_row">
                        <div class="withdrawn_row_title">提现到账账户户名：</div>
                        <span>{{accountName}}</span>
                    </div>
                    <div class="withdrawn_row">
                        <div class="withdrawn_row_title">提现到账账户账号：</div>
                        <span>{{bankNo}}</span>
                    </div>
                    <div class="withdrawn_row">
                        <div class="withdrawn_row_title">提现到账账户开户行：</div>
                        <span>{{openBank}}</span>
                    </div>
                    <div class="withdrawn_row">
                        <div class="withdrawn_row_title">请输入提现金额：</div>
                        <el-input style="width:240px;" placeholder="请输入" v-model="withdrawnAmount"></el-input>
                    </div>
                    <div class="withdrawn_tip">
                        <div class="row_tip">提现金额应小于剩余可提现金额且不能超过十万。</div>
                        <div class="agreen_negotiate">
                            <el-checkbox v-model="agreenFlag" @change="agreenDegotiateChange(agreenFlag)"><span
                                    class="checkbox-label"></span>
                            </el-checkbox>
                            <span>我已阅读并同意</span>
                            <span class="withdrawn_negotiate" @click="checkNegotiate">《账户提现签约电子协议》</span>
                        </div>
                        <div v-if="!signState" class="row_tip" style="color: red;">首次签约电子协议后，返回此页面继续提现</div>
                        <div v-if="signState == 2" class="row_tip" style="color: red;">请完成签约</div>
                    </div>


                </div>
                <div class="withdrawn_safetyCheck" v-if="step==2">
                    <el-button icon="el-icon-back" circle @click="back()"></el-button>
                    <span style="padding-left:10px;">返回上一步</span>
                    <div style="margin-left:140px;">
                        <div class="withdrawn_row">
                            <div class="withdrawn_row_title">短信验证码已发送至：</div>
                            <span style="font-size: 14px;font-weight: 600;padding:0 6px;">{{bankPhone}}</span>
                            <span>手机号码</span>
                            <span v-show="!showTite" style="color: #666666; margin-left: 10px;">{{ this.count }}s</span>
                            <span v-show="showTite"
                                style="color: #30BAC1;padding-left:20px;padding-left:20px;cursor: pointer;"
                                @click="securityCheckGetCode(bankPhone)">获取验证码</span>
                        </div>
                        <div class="withdrawn_row">
                            <div class="withdrawn_row_title">请输入手机短信验证码<span style="color: red;">*</span></div>
                            <el-input style="width:240px; margin-left: 7px;" placeholder="请输入" v-model="smsCode">
                            </el-input>
                        </div>
                    </div>

                </div>
                <div class="withdrawnNext">
                    <el-button v-show="step == 1" class="withdrawnNext-button" type="primary" @click="withdrawnNext()">
                        下一步</el-button>
                    <div v-show="step == 2" class="row_tip" style="color: red;">发起提现成功后，平台将于三个工作日内完成审核。</div>
                    <el-button v-show="step == 2" class="withdrawnNext-button" style="margin-top: 6px;" type="primary"
                        @click="withdrawnSubmit()">发起提现</el-button>
                </div>

            </el-dialog>
            <el-dialog :visible.sync="dialogVisibleImg" width="500px">
                <img width="100%" :src="dialogImageUrl" alt />
            </el-dialog>
        </div>
        <Verify @success="verifiedBy" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }"
      ref="verify1"></Verify>
    </div>
</template>
<script>
    import Vue from "vue";
    import cabinetUtil from "@utils/cabinetUtil.js"
    const TIME_COUNT = 60; //更改倒计时时间
    let validMobile = (rule, value, callback) => {
        let reg = /^1[3-9](\d){9}$/;
        if (!reg.test(value)) {
            callback(new Error("手机号码格式不对"));
        } else {
            callback();
        }
    };

    export default {
        data() {
            let _this = this;
            return {
                title: '商家信息',
                auditName: '审核状态',
                allAmount: 0.00,
                freezenAmount: 0.00,
                canWithdrawnAmount: 0.00,
                state: 0, //0--未审核 1-已审核
                bindState: false, //false-未绑定 true-已绑定
                isShow:true,
                type: 1, //1--个人 2--企业
                stateStr: '',
                typeStr: '',
                name: '',
                sexStr: '',
                cardTypeStr: '',
                cardNo: '',
                validate: '',
                address: '',
                openBank: '',
                branchBank: '',
                branchBankNo: '',
                accountName: '',
                bankNo: '',
                bankPhone: '',
                bankPhoneStr: '',
                mobile: '',
                password: '',
                merchantType: 2,
                id: '',
                signState: '', //签约状态0失败1成功2签约中
                dialogVisibleImg: false,
                dialogImageUrl: '',
                picUrl: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                myForm: {
                    identityReverseUrl: [],
                    identityFrontUrl: [],
                    certificateUrl: [],
                },
                myFormRules: {
                    certificateUrl: [{
                        required: true,
                        message: "请上传营业执照",
                        trigger: ['change', 'blur']
                    }],
                    identityReverseUrl: [{
                        required: true,
                        message: "请上传身份证国徽面",
                        trigger: ['change', 'blur']
                    }],
                    identityFrontUrl: [{
                        required: true,
                        message: "请上传身份证人像面",
                        trigger: ['change', 'blur']
                    }],
                },
                notEdit: false,
                creditCode: '',
                legalName: '',
                memberMobile: '',
                bindMobileState: false,
                dialogVisiblePhone: false,
                showTite: true, // 初始启用按钮
                count: "", // 初始化次数
                timer: null,

                modelList: [{
                        value: 1,
                        label: '平台自营 '
                    },
                    {
                        value: 2,
                        label: '商家运营'
                    },
                    {
                        value: 3,
                        label: '商家经销'
                    }
                ],
                modelTip: '',
                bindPhoneForm: {
                    phone: '',
                    smsCode: '',
                },
                rules: {
                    phone: [{
                        required: true,
                        validator: validMobile,
                        trigger: ['change', 'blur']
                    }],
                    smsCode: [{
                        required: true,
                        message: "请输入验证码",
                        trigger: ['change', 'blur']
                    }],
                },
                userInfo: '',
                dialogVisibleWithdrawn: false,
                agreenFlag: false,
                withdrawnAmount: '',
                smsCode: '',
                step: 1,
                //我的提现
                lastAmount: 0.00,
                monthAmount: 0.00,
                totalAmount: 0.00,
            }
        },
        activated() {
            this.userInfo = this.cache.getLS('userInfo');
            this.myWallet();
            this.detailHandle();
            this.withdrawCcount();
            if (this.userInfo.roleType == 10) {
                this.title = '商家信息';
            } else if (this.userInfo.roleType == 12) {
                this.title = '供应商信息';
            } else if (this.userInfo.roleType == 13) {
                this.title = '经销商信息';
            }
        },
        deactivated() {},
        methods: {
            detailHandle() {
                this.getDetail().then(res => {
                    let data = res.data.data;
                    this.type = data.type;
                    this.typeStr = ['个人', '企业'][data.type - 1];
                    this.name = data.name;
                    this.sexStr = ['男', '女'][data.sex - 1];
                    this.stateStr = ['待审核', '已审核'][data.state];
                    this.notEdit = data.state == 1;
                    this.state = data.state;
                    if (data.cardType == 1) {
                        this.cardTypeStr = '身份证';
                    }
                    this.typeStr = ['个人', '企业'][data.type - 1];
                    if (data.merchantType == 1) {
                        this.modelTip = '平台自营场景下100%分润给自营商家。'
                    }else if(data.merchantType == 3) {
                        this.modelTip = '根据销售价、经销价、成本价进行差价分润，不涉及平台分佣。'
                    } else {
                        this.modelTip = '根据平台设置的商品品类分润比例进行分润。'
                    }
                    this.cardNo = data.cardNo ? data.cardNo.slice(0, 2) + '******' + data.cardNo.slice(data
                        .cardNo.length - 2, data.cardNo.length) : '';
                    this.validate = data.validate;
                    this.address = data.address;
                    this.openBank = data.openBank;
                    this.branchBank = data.branchBank;
                    this.branchBankNo = data.branchBankNo;
                    this.accountName = data.accountName;
                    this.bankNo = data.bankNo ? data.bankNo.slice(0,4) + '*********' + data.bankNo.slice(data.bankNo.length - 4,data.bankNo.length) : '';
                    this.memberMobile = data.memberMobile;
                    this.bindMobileState = data.bindMobileState == 1 ? true : false;
                    this.bankPhoneStr = data.bankPhone ? data.bankPhone.slice(0, 3) + '****' + data.bankPhone
                        .slice(7, 11) : '';
                    this.bankPhone = data.bankPhone;
                    this.mobile = data.mobile;
                    this.merchantType = data.merchantType;
                    this.id = data.id;
                    this.password = '******';
                    // tranceNum 个人时：null--未绑定 1--已解绑;企业时：null--未绑定 1--已绑定
                    if (data.type == 1) {
                        this.bindState = !data.tranceNum || data.tranceNum == '1' ? false : true;
                    } else if (data.type == 2) {
                        this.bindState = data.tranceNum ? true : false;
                    }
                    if(data.type==1){
                        this.isShow = true;
                    }else{
                        if(this.bindState){
                            this.isShow = true;
                        }else{
                            this.isShow = false;
                        }
                    }
                    if (data.type == 2) {
                        this.myForm.identityReverseUrl = [];
                        this.myForm.identityFrontUrl = [];
                        this.myForm.certificateUrl = [];
                        if (data.identityReverseUrl) {
                            this.myForm.identityReverseUrl.push({
                                url: data.identityReverseUrl
                            })
                        }
                        if (data.identityFrontUrl) {
                            this.myForm.identityFrontUrl.push({
                                url: data.identityFrontUrl
                            })
                        }
                        if (data.certificateUrl) {
                            this.myForm.certificateUrl.push({
                                url: data.certificateUrl
                            })
                        }
                        this.creditCode = data.creditCode ? data.creditCode.slice(0,4) + '*********' + data.creditCode.slice(data.creditCode.length - 4,data.creditCode.length) : '';
                        this.legalName = data.legalName;
                    }

                    this.signState = data.signState;
                })
            },
            // 获取商家信息
            getDetail() {
                let dto = {
                    id: this.userInfo.merchantId
                }
                return new Promise((resolve, reject) => {
                    this.post('/mall-service/merchant/v1/detail', dto, {
                        isUseResponse: true,
                        isLoading: true,
                    }).then(res => {
                        if (res.data.code == 0) {
                            resolve(res)
                        } else {
                            reject(res)
                        }

                    }).catch(err => {
                        reject(err)
                    })
                })

            },
            bindAccont(val) {
                // val--1 个人  2--企业
                console.log('绑定账户')
                this.$router.push({
                    path: "/main/merchantManage/merchantInfo/bindAccount",
                    query: {
                        type: val,
                        id: this.id,
                    }
                })
            },
            bindPhone() {
                console.log('绑定手机号码')
                if (this.$refs["bindPhoneForm"]) {
                    this.$refs["bindPhoneForm"].resetFields();
                }
                this.dialogVisiblePhone = true;
                this.bindPhoneForm.phone = this.bankPhone;
            },
            getCode(phone) {
                console.log('this.phone:', this.phone)
                var reg = /^1[3-9](\d){9}$/;
                if (!phone) {
                    return this.$message({
                        message: '请输入手机号！',
                        type: "warning"
                    })
                } else if (reg.test(phone) == false) {
                    return this.$message({
                        message: '请输入正确的手机号！',
                        type: "warning"
                    })
                }
                if (!this.showTite) {
                    return
                }
                let dto = {
                    bizUserId: this.id,
                    phone: phone,
                    verificationCodeType: 9,
                };
                this.showTite = false;
                this.count = TIME_COUNT;
                if (!this.timer) {
                    this.post('/mall-service/merchant/v1/sms/check', dto).then(res => {
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.showTite = true;
                                clearInterval(this.timer); // 清除定时器
                                this.timer = null;
                            }
                        }, 1000);
                        this.$message({
                            message: '发送成功！',
                            type: 'success',
                        })
                    }).catch(err => {
                        this.showTite = true;
                    })
                }

            },
            securityCheckGetCode(phone) {
                var reg = /^1[3-9](\d){9}$/;
                if (!phone) {
                    return this.$message({
                        message: '请输入手机号！',
                        type: "warning"
                    })
                } else if (reg.test(phone) == false) {
                    return this.$message({
                        message: '请输入正确的手机号！',
                        type: "warning"
                    })
                }
                if (!this.showTite) {
                    return
                }

                this.$refs.verify1.refresh();
                this.$refs.verify1.show();
            },
            onSend(captchaVerification) {
                let dto = {
                    mobile: this.bankPhone,
                    captchaVerification: captchaVerification,
                };
                this.showTite = false;
                this.count = TIME_COUNT;
                if (!this.timer) {
                    this.post('/user-service/weChat/sendMobileCode', dto).then(res => {
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.showTite = true;
                                clearInterval(this.timer); // 清除定时器
                                this.timer = null;
                            }
                        }, 1000);
                        this.$message({
                            message: '发送成功！',
                            type: 'success',
                        })
                    }).catch(err => {
                        this.showTite = true;
                    })
                }
            },
            verifiedBy(params) {
                this.onSend(params.captchaVerification);
            },
            cancelBtn() {
                this.dialogVisiblePhone = false;
                if (this.timer) {
                    this.showTite = true;
                    clearInterval(this.timer); // 清除定时器
                    this.timer = null;
                }
            },
            sbumit() {
                this.$refs["bindPhoneForm"].validate((valid) => {
                    if (valid) {
                        console.log('提交')
                        let dto = {
                            bizUserId: this.id,
                            phone: this.bindPhoneForm.phone,
                            verificationCode: this.bindPhoneForm.smsCode,
                        }
                        this.post('/mall-service/merchant/v1/company/bind/mobile',
                            dto, {
                                isUseResponse: true
                            }).then(res => {
                            if (res.data.code == 0) {
                                this.dialogVisiblePhone = false;
                                this.$message({
                                    message: '绑定成功',
                                    type: 'success'
                                })
                                this.detailHandle()
                            }
                        })
                    }
                })
            },
            withdrawnFun() {
                if(this.bindState){
                    this.dialogVisibleWithdrawn = true;
                    this.agreenFlag = false;
                    // 初始化 显示第一步
                    this.step = 1;
                    this.withdrawnAmount = '';
                    this.smsCode = ''
                    if (this.timer) {
                        this.showTite = true;
                        clearInterval(this.timer); // 清除定时器
                        this.timer = null;
                    }
                    // 更新signState状态
                    this.getDetail().then(res => {
                        this.signState = res.data.data.signState;
                    })
                }else{
                    if(this.type == 1){
                        this.bindAccont(1)
                    }else if(this.type == 2){
                        this.bindAccont(2)
                    }
                }
            },
            agreenDegotiateChange(val) {
                // this.getDetail().then(res => {
                //     this.signState = res.data.data.signState;
                // })
            },
            checkNegotiate() {
                this.getDetail().then(res => {
                    this.signState = res.data.data.signState;
                    if (this.signState == 1) {
                        return this.$message({
                            message: '您已签约',
                            type: 'warning'
                        })
                    } else {
                        let dto = {
                            bizUserId: this.userInfo.merchantId,
                        }
                        this.post('/mall-service/merchant/v1/sign/contract', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                window.open(res.data.data.data)
                            }
                        })
                    }
                })


            },
            withdrawnNext() {
                this.getDetail().then(res => {
                    this.signState = res.data.data.signState;

                    if (!this.signState) {
                        return this.$message({
                            message: '请先点击《账户提现签约电子协议》进行签约',
                            type: 'warning'
                        })
                    }
                    if (!this.agreenFlag) {
                        return this.$message({
                            message: '请勾选协议',
                            type: 'warning'
                        })
                    }
                    if (!this.withdrawnAmount) {
                        return this.$message({
                            message: '请输入提现金额',
                            type: 'warning'
                        })
                    } else {
                        let reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/
                        if (!reg.test(this.withdrawnAmount)) {
                            return this.$message({
                                message: '请输入正数，且小数点后最多保留2位',
                                type: 'warning'
                            })
                        }
                        if (parseFloat(this.withdrawnAmount) > parseFloat(100000.00)) {
                            return this.$message({
                                message: '输入金额金额不能超过十万！',
                                type: 'warning'
                            })
                        }
                        if (Number(this.withdrawnAmount) > Number(this.canWithdrawnAmount)) {
                            return this.$message({
                                message: '输入金额不可大于可提现金额！',
                                type: 'warning'
                            })
                        }
                        // if (Vue.config.BASE_URL == 'https://apartment-preapi.wfyundata.com' || Vue.config
                        //     .BASE_URL ==
                        //     'https://apartment-api.wavewisdom.com') {
                        //     if (Number(this.withdrawnAmount) < 10) {
                        //         return this.$message({
                        //             message: '单笔提现金额不低于10元',
                        //             type: 'warning'
                        //         })
                        //     }
                        // }
                        // if (Number(this.withdrawnAmount) > 20000) {
                        //     return this.$message({
                        //         message: '单笔提现金额不超过20000元',
                        //         type: 'warning'
                        //     })
                        // }
                    }

                    // 下一步
                    if (this.signState == 1) {
                        this.step = 2;
                    }
                })

            },
            back() {
                this.step = 1;
            },
            withdrawnSubmit() {
                if (!this.smsCode) {
                    return this.$message({
                        message: '请输入验证码！',
                        type: 'warning'
                    })
                }
                let dto = {
                    bizUserId: this.userInfo.merchantId,
                    amount: cabinetUtil.regYuanToFen(Number(this.withdrawnAmount),100),
                    verificationCode: this.smsCode,
                }
                this.post('/mall-service/allinpay/v1/withdraw/apply', dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        this.dialogVisibleWithdrawn = false;
                        this.$message({
                            message: '提交成功！',
                            type: "success"
                        });
                        // 刷新我的钱包
                        this.myWallet();
                        // 刷新提现统计
                        this.withdrawCcount();
                        // 刷新详情
                        this.detailHandle();
                    }
                })


            },
            // 提现统计
            withdrawCcount() {
                let dto = {
                    memberUserId: this.userInfo.merchantId,
                }
                this.post('/mall-service/allinpay/v1/withdraw/count', dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        let data = res.data.data.data;
                        this.lastAmount = data.lastAmount != null ? Number(data.lastAmount).toFixed(2) : Number(
                            0).toFixed(2);
                        this.monthAmount = data.monthAmount != null ? Number(data.monthAmount).toFixed(2) :
                            Number(0).toFixed(2);
                        this.totalAmount = data.totalAmount != null ? Number(data.totalAmount).toFixed(2) :
                            Number(0).toFixed(2);
                    }
                })
            },
            // 我的钱包
            myWallet() {
                let dto = {
                    bizUserId: this.userInfo.merchantId
                };
                this.post('mall-service/allinpay/v1/wallet/count', dto).then(res => {
                    if (res.code == 0) {
                        this.allAmount = res.data.totalAmount ? Number(res.data.totalAmount).toFixed(2) :
                            Number(0)
                            .toFixed(2);
                        this.freezenAmount = res.data.freezeAmount ? Number(res.data.freezeAmount).toFixed(2) :
                            Number(0)
                            .toFixed(2);
                        this.canWithdrawnAmount = res.data.withdrawAmount ? Number(res.data.withdrawAmount)
                            .toFixed(2) :
                            Number(0).toFixed(2);
                    }
                })
            },
            //配合图片上传确认item
            onPreviewByUploadImg(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisibleImg = true;
            },
            //表单图片单张上传
            onSuccessByUploadImgOnly(item) {
                return (response, file, fileList) => {
                    this.myForm.certificateUrl.push({
                        url: response.data.picPath
                    });
                    this.$refs.clearValidate(['certificateUrl']);
                };
            },
            // 图片删除
            onHandleRemove() {
                return (file, fileList) => {
                    this.myForm.certificateUrl = fileList;
                };
            },

            //表单图片单张上传
            onSuccessByUploadCardUrl1(item) {
                return (response, file, fileList) => {
                    this.myForm.identityReverseUrl.push({
                        url: response.data.picPath
                    });
                    this.$refs.clearValidate(['identityReverseUrl']);
                };
            },
            // 图片删除
            onHandleRemoveCardUrl1() {
                return (file, fileList) => {
                    this.myForm.identityReverseUrl = fileList;
                };
            },

            //表单图片单张上传
            onSuccessByUploadCardUrl2(item) {
                return (response, file, fileList) => {
                    this.myForm.identityFrontUrl.push({
                        url: response.data.picPath
                    });
                    this.$refs.clearValidate(['identityFrontUrl']);
                };
            },
            // 图片删除
            onHandleRemoveCardUrl2() {
                return (file, fileList) => {
                    this.myForm.identityFrontUrl = fileList;
                };
            },
            //图片上传的图片删除
            onBeforeRemoveByUploadImg() {
                return (file, fileList) => {
                    return new Promise((resolve, reject) => {
                        let _this = this;
                        let is = true;
                        if (is) {
                            this.$confirm("确认删除此图片吗？", "提示", {
                                    confirmButtonText: "确定",
                                    cancelButtonText: "取消",
                                    type: "warning"
                                })
                                .then(() => {
                                    console.log('fileList:', fileList)
                                    resolve();

                                    return true
                                })
                                .catch(function () {
                                    reject();
                                });
                        } else {
                            resolve();
                        }
                    })
                }
            },
            //放大图片
            showBig(val) {
                this.dialogImageUrl = val;
                this.dialogVisibleImg = true;
            },
            // 编辑信息提交
            editInfoFun() {
                this.$refs["myForm"].validate((valid) => {
                    if (valid) {
                        let dto = {
                            id: this.id,
                            identityReverseUrl: this.myForm.identityReverseUrl[0].url,
                            identityFrontUrl: this.myForm.identityFrontUrl[0].url,
                            certificateUrl: this.myForm.certificateUrl[0].url,
                        };
                        this.post('/mall-service/merchant/v1/update/company/file', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                            }
                        })
                    }
                })

            },
            // 解绑
            Unbinding() {
                const h = this.$createElement;
                this.$msgbox({
                    title: "确定提示",
                    message: h("p", null, [
                        h(
                            "span", {
                                style: "color: #333;font-size: 16px;margin-left:98px"
                            },
                            "您确定要解绑吗？"
                        ),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(action => {
                    let dto = {
                        bizUserId: this.id,
                    };
                    this.post('/mall-service/merchant/v1/unbind/bankcard', dto).then(res => {
                        if (res.data.tranceNum == null) {
                            this.bindState = false;
                        }
                    })
                })

            },

        },
    }
</script>
<style lang="scss" scoped>
    .merchantInfo-header {
        display: flex;
        justify-content: space-between;
    }



    .cont {
        border: 1px solid #cccccc;
        border-radius: 10px;
        padding: 15px;
        height: 108px;
    }

    .cont_box {
        display: flex;
    }

    .cont_title {
        font-size: 35px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .cont_text2 {
        font-size: 12px;
        color: #cccccc;
    }

    .merchantInfo_info {
        margin-top: 20px;
    }

    .account_info {
        margin-top: 20px;
        padding-left: 100px;
    }

    .info-title {
        font-weight: 600;
        color: #333333;
        margin-bottom: 22px;
    }

    .row {
        margin-bottom: 10px;
    }

    .imgBox {
        display: flex;
    }

    .editInfoBtn {
        width: 520px;
        text-align: center;
        padding: 40px 0;
    }

    .img-list {
        white-space: nowrap;
        overflow-x: auto;

        img {
            margin-right: 10px;
            height: 160px;
            cursor: pointer;
        }
    }

    .address {
        display: flex;
        align-items: center;
    }

    .row-title {
        display: inline-block;
        padding-right: 20px;
        width: 200px;
        text-align: right;
    }

    .row-tip {
        color: #666666;
        font-size: 12px;
        margin-left: 220px;
        margin-top: 10px;
    }

    .row-value {
        width: 300px;
    }

    .merchantInfo_infoBox {
        display: flex;
    }

    .bindAccount-button {
        width: 260px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        border: 1px solid #30BAC1;
        border-radius: 10px;
        color: #333333;
        cursor: pointer;
    }

    .bindAccount-tip {
        padding-top: 20px;
    }

    .getCode {
        color: #30BAC1;
        position: relative;
        left: -87px;
        cursor: pointer;
    }

    .bindPhoneFormTitle {
        margin-left: 82px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .submitBox {
        margin-left: 82px;
    }

    .submitBox-button {
        width: 200px;
    }



    .cont_withdrawn {
        color: #30BAC1;
        cursor: pointer;
    }

    .bindPhone {
        /deep/.el-dialog {
            width: 540px;
            top: 15vh;
        }
    }

    .withdrawn {
        /deep/.el-dialog {
            width: 700px;
            top: 15vh;
        }

        .withdrawn_amount {
            color: red;
            font-size: 20px;
        }

        .withdrawn_content {
            padding-left: 100px;
        }

        .withdrawnNext {
            width: 700px;
            text-align: center;
            margin-top: 15px;
        }

        .withdrawn_row {
            padding: 6px 0;
        }



        .withdrawnNext-button {
            width: 200px;
        }

        .withdrawn_negotiate {
            color: #30BAC1;
            cursor: pointer;
        }

        .withdrawn_row_title {
            display: inline-block;
            width: 149px;
            text-align: right;
        }

        .withdrawn_tip {
            padding-left: 150px;
        }

        .row_tip {
            color: #66666680;
            font-size: 12px;
            padding: 6px 0;
        }

        .agreen_negotiate {
            padding-top: 6px;
        }
    }

    /deep/.el-form-item__label {
        margin-right: 12px;
        color: black;
        font-size: 16px;
    }
</style>